(function(){

	// Home 01
	$("#rev_slider_1_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullwidth",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:30,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1920,1920,778,480],
		gridheight:[1110,1110,600,500],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 02
	$("#rev_slider_2_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullwidth",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:30,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1920,1920,778,480],
		gridheight:[1110,1110,600,500],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 05
	$("#rev_slider_3_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"auto",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"on",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			touch:{
				touchenabled:"on",
				touchOnDesktop:"on",
				swipe_threshold: 75,
				swipe_min_touches: 1,
				swipe_direction: "horizontal",
				drag_block_vertical: false
			}
			,
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1760,1760,778,480],
		gridheight:[850,850,600,500],
		lazyType:"none",
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 07
	$("#rev_slider_4_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullwidth",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			touch:{
				touchenabled:"on",
				touchOnDesktop:"on",
				swipe_threshold: 75,
				swipe_min_touches: 1,
				swipe_direction: "horizontal",
				drag_block_vertical: false
			}
			,
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1920,1920,778,480],
		gridheight:[830,830,600,500],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 09
	$("#rev_slider_5_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"auto",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			onHoverStop:"off",
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1920,1920,778,480],
		gridheight:[880,880,600,500],
		lazyType:"none",
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 11

	$("#rev_slider_6_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"auto",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-style",
				hide_onleave:false,
				direction:"horizontal",
				h_align:"center",
				v_align:"bottom",
				h_offset:0,
				v_offset:20,
                space:5,
				tmp:'<span class="tp-bullet-inner"></span>'
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1220,1220,778,480],
		gridheight:[634,634,600,500],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"on",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 12

	$("#rev_slider_7_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullwidth",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			touch:{
				touchenabled:"on",
				touchOnDesktop:"on",
				swipe_threshold: 75,
				swipe_min_touches: 1,
				swipe_direction: "horizontal",
				drag_block_vertical: false
			}
			,
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1920,1920,778,480],
		gridheight:[820,820,600,500],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 13

	$("#rev_slider_8_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullwidth",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			touch:{
				touchenabled:"on",
				touchOnDesktop:"on",
				swipe_threshold: 75,
				swipe_min_touches: 1,
				swipe_direction: "horizontal",
				drag_block_vertical: false
			}
			,
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1920,1920,778,480],
		gridheight:[890,890,600,500],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 14

	$("#rev_slider_9_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullscreen",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"on",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			touch:{
				touchenabled:"on",
				touchOnDesktop:"on",
				swipe_threshold: 75,
				swipe_min_touches: 1,
				swipe_direction: "horizontal",
				drag_block_vertical: false
			}
			,
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1920,1920,778,480],
		gridheight:[1040,1040,960,720],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		fullScreenAutoWidth:"off",
		fullScreenAlignForce:"off",
		fullScreenOffsetContainer: "#wpadminbar,.site-header-mobile",
		fullScreenOffset: "",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 15

	$("#rev_slider_10_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"auto",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			onHoverStop:"off",
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1760,1760,778,480],
		gridheight:[770,770,600,500],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 17

	$("#rev_slider_11_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullwidth",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			touch:{
				touchenabled:"on",
				touchOnDesktop:"on",
				swipe_threshold: 75,
				swipe_min_touches: 1,
				swipe_direction: "horizontal",
				drag_block_vertical: false
			}
			,
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1920,1920,778,480],
		gridheight:[840,840,600,500],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 17 Product
					
	$("#rev_slider_12_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"auto",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"ares-custom",
				hide_onleave:false,
				direction:"horizontal",
				h_align:"center",
				v_align:"bottom",
				h_offset:0,
				v_offset:-50,
                space:10,
				tmp:''
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1246,1246,778,480],
		gridheight:[670,670,600,500],
		lazyType:"none",
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 17 Special Offer

	$("#rev_slider_13_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"auto",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			touch:{
				touchenabled:"on",
				touchOnDesktop:"on",
				swipe_threshold: 75,
				swipe_min_touches: 1,
				swipe_direction: "horizontal",
				drag_block_vertical: false
			}
		},
		responsiveLevels:[1240,1240,778,480],
		visibilityLevels:[1240,1240,778,480],
		gridwidth:[1170,1170,778,480],
		gridheight:[460,460,500,720],
		lazyType:"none",
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 19

	$("#rev_slider_14_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullscreen",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"on",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"on",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1240,1240,1240,480],
		visibilityLevels:[1240,1240,1240,480],
		gridwidth:[1920,1920,1920,480],
		gridheight:[950,950,950,720],
		lazyType:"none",
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		fullScreenAutoWidth:"off",
		fullScreenAlignForce:"off",
		fullScreenOffsetContainer: "#masthead,#wpadminbar,.site-header-mobile",
		fullScreenOffset: "",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 20

	$("#rev_slider_15_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullwidth",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		visibilityLevels:[1240,1024,778,480],
		gridwidth:1920,
		gridheight:620,
		lazyType:"none",
        minHeight:"600",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 03

	$("#rev_slider_16_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullscreen",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1200,992,750,575],
		visibilityLevels:[1200,992,750,575],
		gridwidth:[1920,1024,778,480],
		gridheight:[1015,768,960,720],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		fullScreenAutoWidth:"off",
		fullScreenAlignForce:"off",
		fullScreenOffsetContainer: "#wpadminbar",
		fullScreenOffset: "",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 04
					
	$("#rev_slider_17_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullwidth",
		dottedOverlay:"none",
		delay:9000,
		navigation: {
			keyboardNavigation:"off",
			keyboard_direction: "horizontal",
			mouseScrollNavigation:"off",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1200,992,750,575],
		visibilityLevels:[1200,992,750,575],
		gridwidth:[1920,1024,778,480],
		gridheight:[970,768,600,720],
		lazyType:"none",
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});

	// Home 06

	$("#rev_slider_18_1").show().revolution({
		sliderType:"standard",
		sliderLayout:"fullscreen",
		dottedOverlay:"none",
		delay:10000,
		navigation: {
			keyboardNavigation:"on",
			keyboard_direction: "vertical",
			mouseScrollNavigation:"on",
             mouseScrollReverse:"default",
			onHoverStop:"off",
			touch:{
				touchenabled:"on",
				touchOnDesktop:"on",
				swipe_threshold: 75,
				swipe_min_touches: 1,
				swipe_direction: "horizontal",
				drag_block_vertical: false
			}
			,
			bullets: {
				enable:true,
				hide_onmobile:false,
				style:"larev-dot2",
				hide_onleave:false,
				direction:"vertical",
				h_align:"right",
				v_align:"center",
				h_offset:80,
				v_offset:0,
                space:5,
				tmp:'<span class="tp-bullet-title">{{title}}</span><span class="tp-bullet-wrap"></span>'
			}
		},
		responsiveLevels:[1200,1200,750,575],
		visibilityLevels:[1200,1200,750,575],
		gridwidth:[1920,1920,778,480],
		gridheight:[1000,1000,960,720],
		lazyType:"none",
		parallax: {
			type:"mouse+scroll",
			origo:"enterpoint",
			speed:400,
          speedbg:0,
          speedls:0,
			levels:[5,10,15,20,25,30,35,40,45,46,47,48,49,50,51,55],
		},
		shadow:0,
		spinner:"spinner0",
		stopLoop:"off",
		stopAfterLoops:-1,
		stopAtSlide:-1,
		shuffle:"off",
		autoHeight:"off",
		fullScreenAutoWidth:"off",
		fullScreenAlignForce:"off",
		fullScreenOffsetContainer: "#masthead,#wpadminbar",
		fullScreenOffset: "",
		disableProgressBar:"on",
		hideThumbsOnMobile:"off",
		hideSliderAtLimit:0,
		hideCaptionAtLimit:0,
		hideAllCaptionAtLilmit:0,
		debugMode:false,
		fallbacks: {
			simplifyAll:"off",
			nextSlideOnWindowFocus:"off",
			disableFocusListener:false,
		}
	});



})(jQuery);